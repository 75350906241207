<template>
  <div>

    <div v-if="loading" class="d-flex justify-content-center mb-3 mt-32">
      <memlist-spinner />
    </div>

    <b-card v-else-if="confirm_done" class="confirm-body mx-auto bg-white shadow-sm mt-16">
      <div class="row">
        <div class="col-12 text-center">
          <i class="fa fa-check-circle" style="font-size: 42px; color: #8dda8d;"></i>
        </div>
      </div>
      <div class="row" style="margin-top: 15px; border-top: 1px solid #ebedf2; padding-top: 20px;">
        <div class="col-12 text-center">
          <h4>
            {{ $t('PUBLIC.RENEW.THANKS_CONFIRM') }}
          </h4>
          <p>{{ $t('PUBLIC.RENEW.REDIRECT') }}</p>
        </div>
      </div>
    </b-card>

    <div v-else>
      <b-card v-if="invalid_link" class="confirm-body mx-auto bg-white shadow-sm mt-16">
        <div>
          <b-alert variant="danger" fade show>
            <div class="d-flex align-items-center justify-content-center p-4">
              <div>
                <span class="svg-icon svg-icon-md text-primary">
                  <i class="fa fa-question-circle color-primary"></i>
                </span>
              </div>
              <div class="ml-4">
                {{ $t('PUBLIC.RENEW.OLD_LINK') }}
                
              </div>
            </div>
          </b-alert>
        </div>
      </b-card>

      <b-card v-else class="confirm-body mx-auto bg-white shadow-sm">
        <h3 class="text-center mt-4 mb-4">{{ $t('PUBLIC.RENEW.CONFIRM_MEMBERSHIP') }}</h3>

        <form class="login-form" @submit.prevent="onSubmit" v-if="show_form">
          
          <b-form-group id="input-group-firstname" :label="$t('MEMBER.FIRSTNAME')" label-for="input-firstname">
            <b-form-input id="input-firstname" v-model="form.firstname" type="text"></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-lastname" :label="$t('MEMBER.LASTNAME')" label-for="input-lastname">
            <b-form-input id="input-lastname" v-model="form.lastname" type="text"></b-form-input>
          </b-form-group>

          <b-form-group 
            id="input-group-email" 
            :label="$t('MEMBER.EMAIL')" 
            label-for="input-email">
            <b-form-input id="input-email" v-model="form.email" type="text"></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-phone" :label="$t('MEMBER.PHONE')" label-for="input-phone">
            <b-form-input id="input-phone" v-model="form.phone" type="text"></b-form-input>
          </b-form-group>

          <h4 class="mt-8 mb-4">{{ $t('PUBLIC.RENEW.MEMBERSHIPS') }}</h4>

          <table
              style="width: 100%; table-layout: fixed;"
              class="table table-head-custom table-vertical-center table-head-bg table-borderless"
            >
            <thead>
              <tr class="text-left">
                <th style="min-width: 40px; " >
                  <span class="text-dark-75">{{$t('PUBLIC.RENEW.COMPANY')}}</span>
                </th>
                <th style="min-width: 40px" >
                  <span class="text-dark-75">{{$t('PUBLIC.RENEW.PERIOD')}}</span>
                </th>
                
              </tr>
            </thead>
            <tbody>
              <template v-for="(item, i) in memberships">
                <tr v-bind:key="i">
                  <td style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; word-break: break-all;">{{ item.name }}</td>
                  <td>{{ item.period }}</td>
                </tr>
              </template>
            </tbody>
          </table>

          <template v-for="(term, i) in terms">
            <TermsCheckbox
              class="my-2"
              v-bind:key="i"
              :term="term"
              @selected="termAccepted"
              @showTerm="showTerm"
            />
          </template>

          <div v-if="loading_confirm" class="d-flex justify-content-center mb-3">
            <memlist-spinner />
          </div>

          <b-alert v-else-if="unable_confirm" variant="danger" fade show>
            <div class="d-flex align-items-center justify-content-center p-4">
              <div>
                <span class="svg-icon svg-icon-md text-primary">
                  <i class="fa fa-question-circle color-primary"></i>
                </span>
              </div>
              <div class="ml-4">
                {{ $t('PUBLIC.RENEW.TRY_AGAIN_LATER') }}
                
              </div>
            </div>
          </b-alert>


          <button
            class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
            type="submit"
            :disabled="!isContinueButtonOK"
          >
            {{ $t('PUBLIC.RENEW.CONFIRM_MEMBERSHIP') }}
          </button>

        </form>
        
        
        <b-modal ref="show-term" size="lg" :title="selected_term.name">
          <p class="my-4" :html="selected_term.template" v-html="selected_term.template"></p>
        </b-modal>

      </b-card>
    </div>

  </div>
  
</template>

<style lang="scss" scoped>

.confirm-body {
  max-width: 640px;
}

.login-body {
  max-width: 500px;
  width: 100%;
  .login-form {
    padding: 2rem 1.5rem 2rem 1.5rem;
    .btn-login {
      font-size: 1.2rem;
      font-weight: 500;
      padding: 1rem 3rem;
      background-color: #5d78ff;
      border-color: #5d78ff;
      &:hover {
        background-color: #3758ff;
        border-color: #2a4eff;
      }
    }
  }
  .warning-message {
    padding: 30px 20px;
  }
}
</style>

<script>
import messageParent from '@/core/services/messageParent';
import { mapState } from 'vuex';
import { MEMBER_LOGIN, MEMBER_LOGOUT } from '@/core/services/store/member.auth.module';
import formatPersonnr, { validate_personnr } from '@/core/services/personnr';
import TermsCheckbox from '@/view/pages/members/register/registerwizard/terms/TermsCheckbox.vue';

import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'renew-confirm',
  components: {
    TermsCheckbox
  },
  mixins: [ toasts ],
  data() {
    return {
      memberships: [],
      member_id: null,
      token: null,
      is_invalid: false,
      show_form: true,
      is_renew_error: false,
      form: {},
      member_renew_id: null,
      region_code: null,
      commune_code: null,
      family: [],
      renew_company_ids: [],
      me: {},
      terms: [],
      accepted: [],
      company: {},
      selected_term: {},
      invalid_link: false,
      renew: null,
      confirm_done: false,
      loading: true,
      loading_confirm: false,
      unable_confirm: false
    };
  },
  mounted() {
    this.member_id = this.$route.params.member_id;
    this.token = this.$route.params.token;

    if (!this.member_id || !this.token) {
      this.is_invalid = true;
      this.show_form = false;
      return;
    }

    this.load_member_renew(this.member_id, this.token);

    setTimeout(function(){
      messageParent(JSON.stringify({ height: document.body.scrollHeight }));
    }, 300);
  },
  methods: {

    onSubmit() {
      this.confirmRenew(this.renew.id, this.me.member_id, []);
    },

    termAccepted(id, value) {

      if (!value) {
        this.accepted = this.accepted.filter(item => item.id !== id);
      }
      else {
        this.accepted.push({ id: id, selected: true });
      }
    },
    showTerm(id) {
      this.selected_term = this.terms.find(item => item.id === id);

      this.$refs['show-term'].show();
    },

    confirmRenew(member_renew_id, member_id, accepted_term_ids) {
      this.loading_confirm = true;

      axios
        .post(`/member_renew/confirm/${member_renew_id}`, { 
          token: this.token, 
          member_renew_id: member_renew_id, 
          member_id: member_id, 
          accepted_term_ids: accepted_term_ids,
          firstname: this.form.firstname,
          lastname: this.form.lastname,
          email: this.form.email,
          phone: this.form.phone,
        })
        .then(res => {
          this.loading_confirm = false;

          if (res.status === 200) {
            this.confirm_done = true;

            this.$store.dispatch(MEMBER_LOGIN, res.data);

            setTimeout(() => {
              this.$router.push({ name: 'profile' });
            }, 5000);
          }
          else {
            this.unable_confirm = true;
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PUBLIC.RENEW.UNABLE_CONFIRM'));
          }
        })
        .catch(err => {
          this.unable_confirm = true;
          this.loading_confirm = false;
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PUBLIC.RENEW.UNABLE_CONFIRM'));
        });
    },

    loadTerms() {
      axios
        .get(`/company/terms/${this.company.company_id}`)
        .then(res => {
          this.terms = res.data;
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PUBLIC.RENEW.UNABLE_GET_TERMS'));
        });
    },

    async load_member_renew(member_id, token) {

      try {
        const res = await axios.get(`/member/renew?member_id=${member_id}&token=${token}&reset=true`);

        if (res.status !== 200) {
          console.log('not 200', res.status);
          this.is_renew_error = true;
          this.invalid_link = true;
          this.loading = false;
          return;
        }


        if (!res.data.is_active) {

          console.log('not active', res.data.is_active);

          this.invalid_link = true;
          this.loading = false;
          return;
        }

        this.me = res.data.member;

        if (!this.me) {
          console.error('me is undefined');
          this.is_renew_error = true;
          this.loading = false;
          return;
        }

        const data = res.data;

        this.form = res.data.member;

        this.member_renew_id = res.data.member_renew_id;
        this.region_code = this.me.region_code;
        this.commune_code = this.me.commune_code;
        this.family = res.data.family;
        this.renew_company_ids = res.data.company_ids;
        this.terms = res.data.terms;
        this.renew = res.data.renew;

        this.memberships = [];

        console.log('data memberships', data.member_companies);

        for (const mc of data.member_companies) {
          this.memberships.push({
            id: mc.member_company_id,
            period: `${mc.vfdt} - ${mc.vtdt}`,
            vfdt: mc.vfdt,
            vtdt: mc.vtdt,
            to_vfdt: mc.vfdt,
            to_vtdt: mc.vtdt,
            name: mc.name
          });
        }

        try {
          var formatted = formatPersonnr(this.me.in_personnr);

          if (formatted.success) {
            this.me.fmt_personnr = formatted.formatted;
            this.me.age = dayjs().diff(
              formatted.year + '-' + formatted.month + '-' + formatted.day,
              'years'
            );
          }
        } catch (err) {
          console.error('unable to format personnr: ' + err);
        }
        
      }
      catch (err) {
        console.error('load_member_renew error', err);


        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PUBLIC.RENEW.MEMBER_NOT_FOUND'));

      }

      this.loading = false;
    },
  },
  computed: {
    isContinueButtonOK() {
      const mandatories = this.terms.filter(item => item.is_mandatory);

      for (const m of mandatories) {
        if (!this.accepted.find(item => item.id === m.id)) {
          return false;
        }
      }

      if (this.loading_confirm) {
        return false;
      }

      return true;
    },
    ...mapState({
      errors: state => state.auth.errors
    })
  }
};
</script>
